import React, { useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/dark.css';
import './App.css';
import heic2any from 'heic2any';

function App() {
  React.useEffect(() => {
    document.querySelectorAll("pre").forEach(block => {
      try { hljs.highlightBlock(block); }
      catch (e) { console.log(e); }
    });
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [, setIsHEIC] = useState(false);
  const [isTerminalVisible, setIsTerminalVisible] = useState(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsProcessing(true); // Set processing state before loading image data
      console.log("1: ", isProcessing)
      if (file.type === 'image/heic') {
        console.log("2: ", isProcessing)
        setIsHEIC(true); 
        const jpegBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(jpegBlob);
        setSelectedImage(imageUrl);

        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);

          canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            setProcessedImage(url);
            setIsProcessing(false);
          }, file.type);
        };
        image.src = imageUrl;
      } else if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);

        const reader = new FileReader();
        reader.onload = function (e) {
          const image = new Image();
          image.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);

            canvas.toBlob((blob) => {
              const url = URL.createObjectURL(blob);
              setProcessedImage(url);
              setIsProcessing(false); // Set processing state to false after image is loaded
            }, file.type);
          };
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please upload a valid image file (jpg, jpeg, png).');
        setIsProcessing(false);
      }
    }
  };

  const handleUploadAnother = () => {
    setSelectedImage(null);
    setProcessedImage(null);
    setIsHEIC(false);
  };

  const handleTerminalClick = () => {
    setIsTerminalVisible(true);
  };

  const handleCloseLayer = () => {
    setIsTerminalVisible(false);
  };

  return (
    <div className="App">
      <header>
        <h1>BOROFISH</h1>
        <p>Remove EXIF data from JPEG, JPG, HEIC and PNG images</p>
      </header>
      
      <nav>
        <ul className="navigation">
          <li><a href="/">Home</a></li>
          <li><a href="#!" onClick={handleTerminalClick}>Terminal</a></li>
          <li><a href="https://apps.apple.com/app/borofish/id1540421101" target="_blank" rel="noreferrer">App</a></li>
        </ul>
      </nav>
      
      <main>
        <h3>Upload an image and automatically remove EXIF information.</h3>
        <input
          type="file"
          accept="image/jpeg,image/jpg,image/png,image/heic"
          id="upload-button"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
        <label htmlFor="upload-button" className="upload-label">
          Upload Image
        </label>
        <p className="tips">Sharing raw photos directly can be dangerous anywhere. Use borofish to remove sensitive information from your photos.</p>
        {selectedImage && (
          <div className="image-container">
            <img src={processedImage || selectedImage} alt="Selected" className="preview-image" />
            {isProcessing && (
              <div className="overlay">
                <p>Removing EXIF data...</p>
              </div>
            )}
            {processedImage && !isProcessing && (
              <div className="overlay">
                <a href={processedImage} download="processed-image.png" className="download-button">
                  Download Processed Image
                </a>
                <button onClick={handleUploadAnother} className="upload-another-button">Upload another</button>
              </div>
            )}
          </div>
        )}
      </main>
      <footer class="copyright">
        © 2025 <a href="/">borofish.com</a>, Made with ❤️ by <a href="https://binhua.org" target="_blank" rel="noopener noreferrer" className="dotted">Bin Hua</a>
      </footer>
      {isTerminalVisible && (
        <div className="layer" onClick={handleCloseLayer}>
          <div className="terminal-content" onClick={(e) => e.stopPropagation()}>
            <h4>Want to run via Command?</h4>
            <p>Copy the following commands and paste them in a macOS Terminal or Linux shell prompt.</p>
            <div className="scriptsinstall">
              <div className="script-code">
                <pre>
                  $ curl https://borofish.com/dl/borofish -o /usr/local/bin/borofish
                </pre>
                <pre>
                  $ chmod +x /usr/local/bin/borofish
                </pre>
                <pre>
                  $ borofish
                </pre>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
